.root {
  fill: var(--colorGrey100);
}
.root1 {
  fill: var(--colorGrey100);
  width: 13px;
}

.filled {
  fill: var(--marketplaceColor);
}
.filledOrange {
  fill: orange;
  width: 13px;
}

