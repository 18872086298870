@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--colorSuccess);
  }

  /* disabled version */
  &:hover + label .boxDisabled,
  &:focus + label .boxDisabled,
  &:checked + label .boxDisabled {
    stroke: var(--colorGrey300);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--colorSuccess);
    stroke-width: 1px;
  }

  /* Display the disabled when checked */
  &:checked + label .checkedDisabled {
    display: inline;
    stroke: var(--colorGrey300);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--colorBlack);
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  height: 32px;
  margin-top: -1px;
  margin-right: 8px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 24px;
  }
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  display: none;
  fill: var(--colorSuccess);
}

.checkedDisabled {
  display: none;
  fill: var(--colorGrey300);
}

.boxDisabled,
.boxSuccess,
.box {
  stroke: var(--colorGrey300);
}

.text {
}
.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);
  padding: 0;
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.categoryFIlterSection{
  padding:9px 36px 6px 36px;
}
.categoryFIlterSection{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.category{
  display: flex;
  border: #ededed solid 1px;
  gap: 4px;
  padding: 2px 6px 2px 3px;
  border-radius: 35px;
  background: #FFF;
  box-shadow:#ededed 0px 2px 2px 2px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.category:hover{
  background: #042f67;
  box-shadow: #ededed 0px 2px 0px 0px;
  color: #FFF;
  transition: 0.5s;
}
.activeCategory{
  display: flex;
  border: #ededed solid 1px;
  gap: 4px;
  padding: 2px 6px 2px 3px;
  border-radius: 35px;
  background: #042f67;
  box-shadow:#ededed 0px 2px 2px 2px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #FFF;
}
.titleCategory{
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.imageBlock{
  border-radius: 50%;
  height: 35px;
  overflow: hidden;
  width: 40px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #ececec solid 1px;
}
.headingContainer{
  padding: 0 36px;

  & h2{
    margin: 8px 0 0 0;
    font-size: 35px;
  }
  & p {
    margin: 0;
  }
  & a{
    color:#a40000;
  }
}
.clearFilters{
  position: absolute;
  background: #F44336;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  line-height: 22px;
  color: #FFF;
  margin-top: -12px;
  margin-left: -7px;
}
.chipLabel {
  padding-bottom: 0px;
}

@media only screen and (min-width: 1850px) {
  .category{
      gap: 2px;
    padding: 2px 5px 2px 3px;
  }
  .imageBlock{
    height: 28px;
    width: 28px;
  }
.titleCategory{
  font-size: 12px;
}
}